@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-hb {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  background-color: var(--lia-bs-light);
  border-radius: var(--lia-bs-border-radius);
  border: 0;
  padding: 15px 0;
  min-height: 80px;
  text-align: left;
  transition: background-color var(--lia-timing-fast), box-shadow var(--lia-timing-fast);

  &.lia-is-button {
    user-select: none;

    &:active,
    &:hover {
      background-color: var(--lia-bs-white);
    }
    &:active {
      box-shadow: var(--lia-bs-box-shadow-sm), 0 0 0 1px var(--lia-bs-border-color);
    }
    &:focus-visible {
      @include default-input-box-shadow();
    }
  }
}

.lia-left {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 55px;
  margin: 0 15px;
}

.lia-content {
  display: block;
  justify-self: flex-start;
  margin-right: 15px;
  overflow-wrap: anywhere;
}

.lia-heading {
  display: block;
  justify-self: flex-start;
  margin-bottom: 0;
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-bold);
  line-height: var(--lia-bs-headings-line-height);
  color: var(--lia-bs-body-color);
  white-space: break-spaces;

  + .lia-subheading {
    margin-top: 4px;
  }
}

.lia-subheading {
  display: block;
  font-size: var(--lia-font-size-xs);
  font-weight: var(--lia-font-weight-normal);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
}

.lia-right {
  margin-right: 15px;
  margin-left: auto;
}
